import React from "react";
import PropTypes from "prop-types";
import { AppBar, Container, Typography, Toolbar, Box, IconButton } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as MarianneLogo } from "./marianne.svg";
import AuthService from "../services/AuthService";

function Layout(props) {
  const { children, isAuthenticated, classes, history } = props;

  const logout = () => {
    AuthService.logout();
    history.push("/");
  };

  return (
    <Box className={classes.root}>
      <AppBar elevation={0} className={classes.appBar}>
        <Toolbar>
          <Box mr={2}>
          <MarianneLogo />
          </Box>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            Vote par procuration
          </Typography>

          {isAuthenticated && (
          <Box>
            <IconButton color="primary" onClick={() => logout()}>
              <ExitToAppIcon />
            </IconButton>
          </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container maxWidth="md" className={classes.container}>
        {children}
      </Container>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    appBar: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired
  }).isRequired
}

Layout.defaultProps = {
  children: null
}

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: "#F8F8F8",
    height: "100vh"
  },
  appBar: {
    backgroundColor: "white"
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: 60
  }
};

export default withStyles(styles)(Layout);