import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@material-ui/core";


function CheckboxField(props) {
  const { label, value } = props;


  if (value === "on") {
    return (
      <Grid item xs={12}>
        <Grid container direction="row" justify="center" alignItems="center">
        <Box mb={2}>
          <Typography variant="button">
            {label}
          </Typography>
        </Box>
        </Grid>
      </Grid>
    )
  } else {
    return null;
  } 

}

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

CheckboxField.defaultProps = {
  value: "off"
}

export default CheckboxField;