import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@material-ui/core";


function HeaderSection(props) {
  const { label } = props;

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justify="center" alignItems="center">
          <Box my={2}>
            <Typography variant="h6" color="primary">
              {label}
            </Typography>
          </Box>
      </Grid>
    </Grid>
  )
}

HeaderSection.propTypes = {
  label: PropTypes.string.isRequired
}

export default HeaderSection;