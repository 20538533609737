export const PUBLIC_ROUTING = [
  {
    path: "/",
    key: "login"
  }
];

export const PRIVATE_ROUTING = [
  {
    path: "/procurations",
    key: "procurations"
  },
  {
    path: "/procurations/:procurationId",
    key: "procuration-detail"
  }
];

export default {
  PUBLIC_ROUTING,
  PRIVATE_ROUTING
};
