import React from "react";
import { Typography, Box, Grid, CircularProgress } from "@material-ui/core";


function Loader() {

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <CircularProgress />
      <Box ml={2}>
        <Typography variant="h6" color="primary">
          Chargement en cours...
        </Typography>
      </Box>
    </Grid>
  )
}

export default Loader;