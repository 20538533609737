import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@material-ui/core";


function Text(props) {
  const { label, value } = props;

  return (
    <Grid item xs={12} sm={6}>
      <Grid container direction="column">
        <Box mb={2}>
          <Typography variant="subtitle2">
            {`${label.charAt(0).toUpperCase()}${label.slice(1)}`}
          </Typography>
          {value !== "" ? (
            <Typography variant="body2">
              {value}
            </Typography>
          ): 
            <Typography variant="caption">
              Non renseigné(s)
            </Typography>
          }
        </Box>
      </Grid>
    </Grid>
  )
}

Text.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

Text.defaultProps = {
  value: ""
}

export default Text;