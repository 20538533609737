import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@material-ui/core";
import Text from "./Text";


function RepetitionField(props) {
  const { label, rows } = props;

  return (
    <Grid container direction="column">
        <Typography variant="button">
          {label}
        </Typography>
        <Box>
          {rows.map(row => (
            <Box key={row.id}>
              <Grid container direction="row">
                {row.champs.filter(champ => champ.type_de_champ.type_champ === "text").map(champRow => (
                  <Text key={champRow.type_de_champ.id} value={champRow.value} label={champRow.type_de_champ.libelle} />
                ))}
              </Grid>
            </Box>
          )
          )}
        </Box>
    </Grid>
  )
}

RepetitionField.propTypes = {
  label: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired
}

export default RepetitionField;