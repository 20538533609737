import React, { useState }   from "react";
import * as yup from 'yup';
import { useHistory } from "react-router-dom";
import { Paper, Typography, Box, Grid, TextField, Button } from "@material-ui/core";
import AuthService from "../../services/AuthService";


function Login()  {
  const history = useHistory();
  const [ userEmail, setUserEmail ] = useState("");
  const [ userPassword, setUserPassword ] = useState("");

  const schema = yup.object().shape({
    userEmail: yup.string().email().required(),
    userPassword: yup.string().required()
  });

  const isFormValid = () => schema.isValidSync({userEmail, userPassword});

  const login = () => {
    AuthService.login({
      email: userEmail,
      password: userPassword
    }).then(response => {
      const { status } = response;

      if (status === 200) {
        AuthService.setUserData({ email: userEmail });
        history.push("/procurations");
      }
    })
  }

  return (
    <Paper elevation={3}>
      <Box p={2}>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >

      <Typography variant="h4">Connectez-vous</Typography>

      <Grid item xs={12} sm={10} md={8} lg={6} style={{width: "100%"}}>
        <form noValidate autoComplete="off">
          <Box mt={4} mb={2}>
            <TextField
              id="email"
              onChange={e => {setUserEmail(e.target.value)}}
              required
              label="Email"
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="password"
              onChange={e => {setUserPassword(e.target.value)}}
              type="password"
              required
              label="Mot de passe"
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box bt={2} mb={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => login()}
              disabled={!isFormValid()}
              disableElevation
              fullWidth>
              Se connecter
            </Button>
          </Box>
        </form>
      </Grid>
    </Grid>
    </Box>
  </Paper>
  )
}

export default Login;
