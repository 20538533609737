import axios from "axios";

const token = "1e09cbc541374f0d863df28010ff8648";

const ProcurationsService = {
  getProcurations: (startDate, endDate, status) => {
    const url = "https://www.docapost.io/hub/v2/data/boxNumber/PROCURATION_ELECTRONIQUE/serialNumber/RECORD/code/record";

    const data = [
      {
          "fieldName": "created_at",
          "operation": "gte",
          "values": [startDate]
      },    {
          "fieldName": "created_at",
          "operation": "lt",
          "values": [endDate]
      }
  ];

  return axios({
    method: "post",
    url,
    data,
    headers: { Authorization: `Bearer ${token}`}
  });
  },
  getProcurationDetail: id => {
    const url = "https://www.docapost.io/hub/v2/data/boxNumber/PROCURATION_ELECTRONIQUE/serialNumber/RECORD/code/record";

    const data = [{
      "fieldName": "id",
      "operation": "eq",
      "values": [id]
    }];

    return axios({
      method: "post",
      url,
      data,
      headers: { Authorization: `Bearer ${token}`}
    });
  } 
} 

export default ProcurationsService;