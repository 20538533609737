import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format, sub } from "date-fns";
import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import ProcurationsService from "../../services/ProcurationsService";
import { withStyles, IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from '@material-ui/core/Paper';
import Loader from "../../components/Loader";

function Procurations(props)  {
  const { classes } = props;
  const history = useHistory();
  const [procurations, setProcurations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const date = new Date();
    const endDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    const startDate = format(sub(date, { weeks: 1 }), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    ProcurationsService.getProcurations(startDate, endDate, "closed").then(response => {
      const { data } = response;
      const { results } = data;

      const myProcurations = []; 

      results.forEach(result => {
        const object = JSON.parse(result.value);
        object.resultId = result.id;
        myProcurations.push(object);
      });

      setProcurations(myProcurations);
      setIsLoading(false);
    });
  }, []);

  return (
  <Box>
    {isLoading ? (
      <Loader />
    ) : (
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="proc table">
            <TableHead>
              <TableRow>
                <HeaderCell>Utilisateur</HeaderCell>
                <HeaderCell>Email</HeaderCell>
                <HeaderCell>Créé le</HeaderCell>
                <HeaderCell>Etat</HeaderCell>
                <HeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {procurations.map((procuration, i) => (
                <TableRow
                  key={`row-${i.toString()}`}
                  hover={true}
                  className={classes.tableRow}
                  onClick={() => { history.push(`/procurations/${procuration.resultId}`)}}
                >
                  <TableCell>
                  {`${procuration.individual.civilite} ${procuration.individual.nom} ${procuration.individual.prenom}`}
                  </TableCell>
                  <TableCell>
                    {procuration.email}
                  </TableCell>
                  <TableCell>
                    {format(new Date(procuration.created_at), "'Le' dd/MM/yyyy 'à' HH:mm")}
                  </TableCell>
                  <TableCell>
                    {procuration.simplified_state}
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )}
  </Box>
  )
}

const HeaderCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}))(TableCell);

Procurations.propTypes = {
  classes: PropTypes.shape({
    tableRow: PropTypes.string.isRequired
  }).isRequired
}

const styles= {
  tableRow: {
    "&:hover": {
      cursor: 'pointer'
    }
  }
}

export default withStyles(styles)(Procurations);
