import AuthService from "../services/AuthService";
import UrlUtils from "../utils/UrlUtils";
import { PUBLIC_ROUTING, PRIVATE_ROUTING } from "./routes";

export default class Resolver {
  static resolve = (routeKey, params = {}) => {
    const allRoutes = [...PUBLIC_ROUTING, ...PRIVATE_ROUTING];
    const route = allRoutes.find(r => r.key === routeKey);

    if (route) {
      let { path } = route;
      const queryParams = {};

      Object.keys(params).forEach(paramKey => {
        if (path.indexOf(`:${paramKey}`) !== -1) {
          path = path.replace(`:${paramKey}`, params[paramKey]);
        } else {
          queryParams[paramKey] = params[paramKey];
        }
      });

      if (path.indexOf(":") === -1) {
        if (Object.values(queryParams).length) {
          path += `?${UrlUtils.createQueryParams(queryParams)}`;
        }

        return path;
      }
    }

    return Resolver.resolve(
      AuthService.isAuthenticated ? "login" : "procurations",
      params
    );
  };
}