import axios from "axios";

const USER = "user";

const authService = {
  login: body => axios.post("https://www.mocky.io/v2/5ed7a46e320000a3cc274a78", body),
  isAuthenticated: () =>
    localStorage.getItem(USER) ? localStorage.getItem(USER) : false,
  setUserData: userData => { localStorage.setItem(USER, JSON.stringify(userData))},
  getUserData: () => JSON.parse(localStorage.getItem(USER)),
  logout: () => {
    localStorage.removeItem(USER);
  }
} 

export default authService;
