import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@material-ui/core";


function AddressField(props) {
  const { label, value } = props;

  return (
    <Grid item xs={12} sm={6}>
      <Grid container direction="column">
        <Box mb={2}>
          <Typography variant="subtitle2">
            {label}
          </Typography>
          {value !== "" ? (
            <Box>
              <Typography variant="body2">
                  {value.match('([0-9a-zA-Z,. ]*) ?([0-9]{5}) ?([a-zA-Z]*)')[1]}
              </Typography>
              <Typography variant="body2">
                {`${value.match('([0-9a-zA-Z,. ]*) ?([0-9]{5}) ?([a-zA-Z]*)')[2]} ${value.match('([0-9a-zA-Z,. ]*) ?([0-9]{5}) ?([a-zA-Z]*)')[3]}`}
              </Typography>
            </Box>
          ): 
            <Typography variant="caption">
              Non renseigné(s)
            </Typography>
          }
        </Box>
      </Grid>
    </Grid>
  )
}

AddressField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

AddressField.defaultProps = {
  value: ""
}

export default AddressField;