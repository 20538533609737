// Dépendances
import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

// Services
import AuthService from "../services/AuthService";

// Routes
import Login from "./components/Login";
import Procurations from "./components/Procurations";
import ProcurationDetail from "./components/ProcurationDetail";

// Components
import Layout from "../components/Layout";

// Dépendances spécifiques aux routes
import Resolver from "./Resolver";
import history from "./history";
import { PUBLIC_ROUTING, PRIVATE_ROUTING } from "./routes";

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthService.isAuthenticated()
  );

  useEffect(() => {
    history.listen(() => {
      setIsAuthenticated(AuthService.isAuthenticated());
    })
  }, []);

  const getRouteComponent = (route) => {
    switch (route.key) {
      case "login":
        return Login;
      case "procurations":
        return Procurations;
      case "procuration-detail":
        return ProcurationDetail;
      default:
      break;
    }

    return null;
  };

  const renderRoute = (route) => {
    const component = getRouteComponent(route);

    if (!component) {
      return null;
    }

    console.log(Resolver.resolve("procurations"));

    return <Route exact component={component} {...route} />
  };

  return (
    <Layout isAuthenticated={isAuthenticated} history={history}>
      <Router history={history}>
        <Switch>
          {!isAuthenticated && PUBLIC_ROUTING.map(renderRoute)}
          {!isAuthenticated && <Route component={Login} />}

          {isAuthenticated && PRIVATE_ROUTING.map(renderRoute)}
          {isAuthenticated && <Redirect to={Resolver.resolve("procurations")} />}
        </Switch>
      </Router>
    </Layout>
  );
};

export default Routes;
