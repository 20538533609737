import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Link, Button, Grid, Divider } from "@material-ui/core";
import { format } from "date-fns";
import Loader from "../../components/Loader";
import ProcurationsService from "../../services/ProcurationsService";
import HeaderSection from "../../components/fields/HeaderSection";
import Text from "../../components/fields/Text";
import DateField from "../../components/fields/DateField";
import AddressField from "../../components/fields/AddressField";
import CheckboxField from "../../components/fields/CheckboxField";
import RepetitionField from "../../components/fields/RepetitionField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Alert, AlertTitle }  from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';

function ProcurationDetail(props)  {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState([]);
  const [procuration, setProcuration] = useState({});
  const [successfulControls, setSuccessControls] = useState([]);
  const [failedControls, setFailedControls] = useState([]);
  const [open, setOpen] = React.useState(false);

  const id = props.match.params.procurationId;


  useEffect(() => {
    ProcurationsService.getProcurationDetail(id).then(response => {
      const { data } = response;
      const item = data.results[0];

      const procurationObject = JSON.parse(item.value);
      console.log(procurationObject);
      let { controls } = procurationObject;

      if (controls === undefined) {
        controls = [];
      }

      setSuccessControls(controls.filter(control => control.state === true));
      setFailedControls(controls.filter(control => control.state === false));
      setProcuration(procurationObject);
      setIsLoading(false);
    })
  }, [id]);

  const getFieldComponent = champ => {
    const { type_de_champ } = champ;
    const type = type_de_champ.type_champ;

    switch(type) {
      case "header_section":
        return <HeaderSection label={type_de_champ.libelle} />
      case "text":
        return <Text label={type_de_champ.libelle} value={champ.value} />
      case "drop_down_list":
        return <Text label={type_de_champ.libelle} value={champ.value} />
      case "phone":
        return <Text label={type_de_champ.libelle} value={champ.value} />
      case "email":
        return <Text label={type_de_champ.libelle} value={champ.value} />
      case "date":
        return <DateField label={type_de_champ.libelle} value={champ.value} />
      case "address":
        return <AddressField label={type_de_champ.libelle} value={champ.value} />
      case "checkbox":
        return <CheckboxField label={type_de_champ.libelle} value={champ.value} />
      case "repetition":
        return <RepetitionField label={type_de_champ.libelle} rows={champ.rows} />
      default:
        break;
    }
  
  
    return <Box>{champ.type_de_champ.libelle}</Box>
  
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
    <Box mb={4}>
      <Box mb={1}>
        <Link component="button" variant="caption" onClick={() => history.goBack()}>Retour</Link>
      </Box>
      <Paper elevation={3}>
        <Box></Box>
        <Box p={2}>
          {isLoading ? (
            <Loader />
          ) : (
          <Box>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button variant="contained" color="primary" onClick={handleClickOpen} disableElevation>
                Contrôler les données
              </Button>
            </Grid>
            <Box mb={2}>
              <Grid container spacing={1}>
              {procuration.champs.map(champ => (
                  getFieldComponent(champ)
              ))}
              </Grid>
            </Box>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button variant="contained" color="primary" onClick={handleClickOpen} disableElevation>
                Contrôler les données
              </Button>
            </Grid>
          </Box>
          )}
        </Box>
      </Paper>
      
      {!isLoading && (
        <Box mt={1}>
          <Link component="button" variant="caption" onClick={() => history.goBack()}>Retour</Link>
        </Box>
      )}
    </Box>
    <Dialog
      open={open}
      hideBackdrop={true}
      disableScrollLock={true}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Contrôle des données</DialogTitle>
      <DialogContent>
        {failedControls.length === 0 && (
          <Alert severity="success">
            <AlertTitle>Le formulaire est valide</AlertTitle>
            Vous pouvez valider la demande de procuration
            {successfulControls.length > 0 && (
              <Box mt={1}>
                <Divider />
              </Box>
            )}
            <Box mt={1}>
              {successfulControls.map(successfulControl => (
                <Box key={successfulControl.id} mb={1}>
                  {successfulControl.field_type === "text" && (
                    <Box>
                      <strong>{successfulControl.value}</strong> {successfulControl.reason}
                    </Box>
                  )}
                  
                  {successfulControl.field_type === "date" && (
                    <Box>
                      <strong>{format(new Date(successfulControl.value), "dd/MM/yyyy")}</strong> {successfulControl.reason}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Alert>
        )}

        {failedControls.length > 0 && (
          <Alert severity="error">
          <AlertTitle>Le formulaire n'est pas valide</AlertTitle>
          {failedControls.length > 0 && (
            <Box mt={1}>
              <Divider />
            </Box>
          )}
          <Box mt={1}>
            {failedControls.map(failedControl => (
              <Box key={failedControl.id} mb={1}>
                {failedControl.field_type === "text" && (
                  <Box>
                    <strong>{failedControl.value}</strong> {failedControl.reason}
                  </Box>
                )}
                
                {failedControl.field_type === "date" && (
                  <Box>
                    <strong>{format(new Date(failedControl.value), "dd/MM/yyyy")}</strong> {failedControl.reason}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Alert>            
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={handleClose} color="secondary">
          Refuser la demande
        </Button>
        {failedControls.length === 0 && (
          <Button variant="contained" disableElevation onClick={handleClose} color="primary">
            Valider la demande
          </Button>
        )}
      </DialogActions>
    </Dialog>
    </Box>
  )
}

export default ProcurationDetail;
